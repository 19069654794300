.mobile_header {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px 30px;
    @media screen and (min-width: 992px){
      display: none;
    }
  }
  &__links {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }
  &__title {
    font-size: 16.12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.242px;
    margin: 0;
    &_wrapper {
      padding: 4px 0 6px;
    }
  }
}