.uploading_documents {
  &__wrapper {
    width: 100%;
  }
  &__content {
    padding: 20px 0;
  }
  &__title,
  &__subtitle {
    font-style: normal;
    line-height: 15px;
    letter-spacing: 0.363px;
    margin-bottom: 0;
  }
  &__title {
    font-size: 12.09px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 8px 0 7px;
  }
  &__subtitle {
    font-size: 12.09px;
    font-weight: 400;
    padding: 3px 0 2px;
  }
  &__btn {
    max-width: 143px !important;
    min-width: auto !important;
    font-weight: 400  !important;
    letter-spacing: 0.101px  !important;
    padding: 10px 24px 15px !important;
    border-radius: 12px !important;
    text-transform: capitalize !important;
    margin: 0 !important;
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }
  }
}
.dropzone__wrapper {
  padding-bottom: 20px;
}