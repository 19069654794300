.subscription-details {
  .input-label {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.subscription-balance__amount,
.subscription-discounts__amount{
  font-size: 28px;
  font-weight: 500;
  line-height: 30px;
}

.subscription-balance {
  margin-bottom: 36px;
}

.subscription-discounts__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 480px;
}

.subscription-discounts__item {
  max-width: 230px;
}

.history-block {
  padding-top: 90px;
}

.history-block__title {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 40px;
}

.history-block__year {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 7px;
}

.history-block__item {
  display: flex;
  justify-content: space-between;
  max-width: 480px;
  padding: 14px 0 17px;
}

.history-block__name {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.history-block__details {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.history-block__price {
  font-size: 16px;
  font-weight: 400;
}