.check-installment {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 16px;
}

.default-text__installment {
  font-size: 10px;
  line-height: 15px;
}

.t-bank {
  width: 80px;
}