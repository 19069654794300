.social-btn {
  box-shadow: 0px 32px 32px rgba(0, 0, 0, 0.02), 0px 16px 16px rgba(0, 0, 0, 0.02), 0px 8px 8px rgba(0, 0, 0, 0.02), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 2px 2px rgba(0, 0, 0, 0.02);
  width: 45px;
  height: 45px;
  border-radius: 25px;
  background: rgba(245, 245, 245, 1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  margin: 0 7px;
  background-repeat: no-repeat;
  background-position: center;
  transition: .3s ease;
  &.social-google {
    background-image: url(../../../styles/assets/images/sv/google2.svg);
  }
  &.social-yandex {
    background-image: url(../../../styles/assets/images/sv/ya.svg);
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
  &.social-outlook {
    background-image: url(../../../styles/assets/images/sv/outlook.svg);
  }
  &:hover {
    box-shadow: 0px 13px 16px rgba(0, 0, 0, 0.1), 0px 9.00525px 10.1598px rgba(0, 0, 0, 0.0787172), 0px 5.87464px 6.11079px rgba(0, 0, 0, 0.0644315), 0px 3.5172px 3.48921px rgba(0, 0, 0, 0.054344), 0px 1.84198px 1.9312px rgba(0, 0, 0, 0.045656), 0px 0.758017px 1.07289px rgba(0, 0, 0, 0.0355685), 0px 0.174344px 0.550437px rgba(0, 0, 0, 0.0212828);
  }
  &:active {
    background-color: rgba(245, 245, 245, 1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    &:focus {
      box-shadow: none;
    }
  }
}

.btn-secondary:hover {
  background-color: initial;
}

.modal-body {
  display: flex;
  justify-content: center;
}
.modal_body-column {
  flex-direction: column;
}