b {
  font-weight: inherit;
}

.subscription_info_component {
  margin-top: 22px;
  font-size: 28.57px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 30px;
  letter-spacing: -0.143px;
  @media screen and (min-width: 200px) and (max-width: 599px) {
    margin-top: 20px;
  }
  .mb-5 {
    @media screen and (min-width: 200px) and (max-width: 599px) {
      margin-bottom: 40px !important;
    }
  }

  .subscription_info_label_text {
    font-size: 16.12px;
    line-height: 20px;
    letter-spacing: 0.242px;
    padding: 10px 0 6px;
    font-weight: 400;
  }

  .subscription_info_label_amount {
    color: #0056B3;
  }

  .price_lable {
    font-size: 16.12px;
    color: #000000;
    line-height: 20px;
    letter-spacing: 0.242px;
    margin: 10px 0 6px;
    font-weight: 400;
  }

  .subscription_info_discount {
    width: calc(50% - 20px);
  }

  .subscription_info_discount_message {
    display: flex;
    border: 1px solid #00000017;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    color: #000000;
    width: 100%;
    margin-bottom: 10px;
  }

  .subscription_info_price {
    margin-bottom: 6px;
  }

  .warning {
    color: #EB5F46;
  }

  .success {
    color: #2BA046;
  }

  .equality {
    color: #0056B3;
    gap: 20px;
    @media screen and (max-width: 320px) {
      margin-bottom: 20px;
    }
  }

  ul {
    margin-top: 6px;
    list-style-position: inside;
    padding: 0;
    margin-bottom: 0;

    li {
      margin-top: 3px;
    }
  }
  @media screen and (max-width: 400px) {
    border-radius: 0;
    border-left: none;
    border-right: none;
    padding: 10px 0;
  }
  @media screen and (max-width: 320px) {
    margin-top: 0;
    padding: 0;
    .mb-5 {
      margin-bottom: 28px !important;
    }
  }

  .icon-wrapper {
    margin-top: -2px;
    width: 18px;
    height: 18px;
  }
}