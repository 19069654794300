.header {
  &_white {
    color: #fff;
  }
  &__logo {
    &_body {
      height: 100%;
    }
    &_content {
      @media screen and (max-width: 470px) {
        padding-top: 15px;
      }
    }
  }
  &_navigation {
    &_menu {
      padding: 40px 0 20px;
      @media screen and (max-width: 470px) {
        padding: 10px 20px 0;
      }
      @media screen and (max-height: 820px) {
        padding: 25px 0 20px;
      }
    }
    &__wrapper {
      max-width: 480px;
      margin-right: auto;
      margin-left: auto;
      @media screen and (max-width: 470px) {
        margin-bottom: 0;
      }
      @media screen and (max-height: 820px) {
        margin-bottom: 10px;
      }
    }
    &__icon {
      flex: 0 0;
    }
  }
  &__step {
    &_white {
      &::before {
        border: 2px solid white;
      }
    }
  }
  &__logo {
    cursor: pointer;
    &_content {
      @media screen and (max-width: 470px) {
        flex: 1 1;
      }
    }
    &_wrapper {
      @media screen and (max-width: 470px) {
        padding-top: 15px;
      }
    }
  }
}
