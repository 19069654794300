.step {
  margin-bottom: 22px;
  &__item {
    font-size: 12px;
    text-transform: uppercase;
    color: #C7C7C7;
    margin-bottom: 15px;
    &.active {
      color: black;
      cursor: pointer;
      .step__count {
        &:before {
          border: 1px solid black;
        }
      }
    }
  }
  &__count {
    padding: 1px 7px 4px 7px;
    margin-right: 5px;
    border-radius: 50%;
    position: relative;
    line-height: 1.2;
    letter-spacing: -.5pt;
    box-sizing: content-box;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 1px);
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid #c7c7c7;
      border-radius: 50%;
      width: 22px;
      height: 22px;
    }
    &.active {
      border: 1px solid black;
    }
  }

}

.book-title {
  font-size: 20px;
  line-height: 24px;
  padding-left: 33px;
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    font-size: 14px;
    line-height: 20px;
  }
}

.step-done-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}