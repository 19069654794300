.subscription{
    font-size: 21px;

    &.subscription_final{
        width: 85%;
        line-height: 24px;
    }
    .instruction_title{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .instruction_list{
        list-style: none;
        padding: 0;
        li{
            margin-top: 10px;
        }
    }
    .instruction{
        margin-bottom: 40px;
    }
    @media screen and (max-width: 500px){
        font-size: 18px;
    }
    form {
        .mt-5 {
            @media screen and (min-width: 200px) {
                margin-top: 0 !important;
            }
            @media screen and (max-width: 320px) {
            margin-top: 0 !important;
            }
        }
        .pt-3 {
            padding: 60px 0 40px;
            @media screen and (min-width: 200px) {
                padding-top: 0 !important;
            }
        }
    }
}
