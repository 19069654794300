.reg-button {
  margin-top: 9px;
}
.login-row{
  .login-link-row {
    text-align: center;
    font-size: 15px;
    margin-bottom: 22px;
    .login-link{
      color: #7B61FF;
      text-decoration: underline;
    }
  }
}

.button_close {
  all: unset;
  display: inline-block;
  cursor: pointer;
}

.button_close img {
  display: block;
  width: 100%;
  height: auto;
}


.email-correct {
  position: absolute;
  top: 42px;
  right: 13px;
}

.validation-message {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 10px;
  width: 100%;
}

.validation-label {
  font-size: 12px;
  margin-top: 10px;
  color: #000;
}

.valid {
  margin: 0 1px;
  display: inline-block;
  min-width: 14px;
  height: 9px;
  border-radius: 1px;
}

.none {
  background-color: #EBEBEB;
}

.easy {
  background-color: #E84040;
}

.medium {
  background-color: #D98906;
}

.hard {
  background-color: #6EAF42;
}

.easy-text {
  color: #E84040;
}

.medium-text {
  color: #D98906;
}

.hard-text {
  color: #6EAF42;;
}

#login.login {
  .flag-dropdown {
    margin-left: 12px;
    background-color: transparent;
    border: none;
    .selected-flag:hover {
      background-color: transparent;
    }
    &.open {
      background-color: transparent;
      .selected-flag {
        background-color: transparent;
      }
    }
  }
  .login-phone-input {
    width: 100%;
    border: 2px solid #bfbfbf;
    height: 46px;
    padding: 15px 20px 15px 48px;
    font-size: 15px;
    &.has-error {
      border: 1px solid rgba(224, 0, 0, 0.75);
    }
    &:focus {
      box-shadow: none;
    }
  }
  .password-input {
    padding-right: 35px;
  }
}