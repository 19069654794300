.btn {
  &.btn-primary {
    margin: auto;
    display: block;
    max-width: 470px;
    cursor: pointer;
    min-width: 260px;
    padding: 17px 10px;
    text-align: center;
    text-transform: uppercase;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 8px;
    -webkit-transition: .3s;
    transition: .3s;
    z-index: 100;

    font-family: "Basis Grotesque Pro", sans-serif;
    font-size: 20.15px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.1px;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      background: #151515;
      color: #fff;
    }
    &:focus {
      &:active {
        box-shadow: none;
      }
    }
  }
  &.form-button {
    margin-bottom: 18px;
  }
  &.btn-blue {
    background-color: #007bff;
  }
  &.z-index-1 {
    z-index: 1;
  }
  @media screen and (max-width: 991px) {
    &.mobile-width-100 {
      width: 100%;
      max-width: 100%;
    }
  }
}
.small-button.btn.btn-primary {
  margin-left: 0;
  font-size: 17px;
  min-width: 200px;
  padding: 12px 10px;
  &.center{
    margin: auto;

  }
}
