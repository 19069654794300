.modal-dialog.popup.add-vk {
    height: 100%;
    display: flex;
    align-items: center !important;
}

.add-vk {
    .modal-body > .submit-button {
        display: block;
        max-width: 470px;
        cursor: pointer;
        min-width: 260px;
        padding: 17px 10px;
        text-align: center;
        text-transform: uppercase;
        background-color: #000;
        color: #fff;
        border: none;
        border-radius: 8px;
        transition: .3s;
        z-index: 100;
        font-family: "Basis Grotesque Pro", sans-serif;
        font-size: 20.15px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.1px;
        margin-top: 18px;
    }

    .modal-body {
        justify-content: center;
        flex-wrap: wrap;
    }
}

