.fields {
  margin: 0 60px;
  @media screen and (max-width: 992px) {
    margin: 0 20px;
  }
}
.field{
  padding: 20px 0;
  width: 230px;
    .input-label{
        display: block;
        margin: 0 !important;
        padding: 8px 0 7px;
    }
    .validation-img{
        position: relative;
        margin-left: 5px;
        top: -2px;
    }
    input[type="radio"] {
        display: none;
        & + label {
          position: relative;
          cursor: pointer;
          color: rgba(204, 204, 204, 1);
          margin-top: 0 !important;
          margin-bottom: 0;
          padding: 0 10px;
          font-size: 20px;
          &:nth-of-type(1) {
            margin-left: -10px;
          }
        }
        &:checked + label {
          color: #000;
          &:before {
            content: '';
            position: absolute;
            display: block;
            width: 40px;
            height: 40px;
            border: 1px solid black;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%)
          }
        }
    }
    input[type="text"] {
      height: 25px;
      margin-bottom: 5px;
    }
    input[type="tel"] {
      background: none;
      border: none;
      width: auto;
      height: 25px;
      padding-left: 40px;
    }
    select {
      height: 25px;
      margin-top: 0;
      margin-bottom: 5px;
    }
    .react-tel-input {
      padding-bottom: 5px;
      .flag-dropdown {
        background: none;
        border: none;
        border-radius: 0;
        height: 25px;
        .selected-flag {
          padding: 0;
        }
      }
    }
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}