.selector {
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
  &__trigger {
    width: calc(100%);
    border: 1px solid #444;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4eiIvPgo8L3N2Zz4);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 50%;
    @media screen and (max-width: 500px) {
      background-position: calc(100% - 10px) 10px;
    }
  }
  &__clicked {
    border-color: #e50000 !important;
  }
  &__list {
    list-style-type: none;
    margin-top: -1px;
    padding-left: 0;
    border: 1px solid black;
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    z-index: 10;
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
    box-shadow: 3px 3px 8px rgba(0,0,0,0.5);
    &.d-none {
      box-shadow: none;
    }
  }
  &__item {
    padding: 15px 10px;
    border-bottom: 1px solid black;
    &:last-child {
      border-bottom: 0;
    }
    &.weekend {
      color: #e50000;
    }
  }
}
