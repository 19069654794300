.checkout__input-wrapper {
  .payment-methods-block {
    .select {
      .bordered-input {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("../../../styles/assets/images/input/icoOpenSelect.png");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 10px);
        background-position-y: 10px;
      }
    }

    .bordered-input {
      border: 1px solid rgba(68, 68, 68, 1);
      border-radius: 5px;
      padding: 10px 10px 12px 10px;
      height: 25px;
      min-height: 50px;
      margin-left: -5px;
      margin-bottom: 6px;
      font-size: 20px;
      background: none;
      width: 100%;
      outline: none;
      display: block;
      color: #495057;

      .placeholder {
        color: #495057;
      }

      &::placeholder {
        color: #495057;
      }
    }

    .payment-extra-block {
      .promo-input-block {
        position: relative;
        padding-bottom: 5px;
        @media screen and (min-width: 200px) and (max-width: 359px) {
          margin-bottom: 10px !important;
        }
      }
      .input-block-wrap {
        position: relative;
      }
    }

    .apply-button {
      width: 100%;
      max-width: initial;
      margin: 48px 0 0 -5px;
    }

    .payment-block {
      margin-top: 30px;

      .payment-pay-extra-block {
        display: flex;
        align-items: center;

        label {
          display: inline-flex;
          align-items: center;
          border: 1px solid #000;
          border-radius: 10px;
          padding: 10px 0 10px 15px;
          width: initial;
          margin-bottom: 0;
          &::before{
            position: static;
            transform: none;
            margin-right: 12px;
            left: 15px;
          }
          &:after{
            left: 15px;
          }
        }

        .checked:before {
          background: #000;
        }

        .payment-pay-extra-checkbox {
          border: none;
        }
      }

      .payment-divider {
        border: 1px solid rgba(229, 229, 229, 1);
        width: 100%;
        margin: 20px 0;
        @media screen and (max-width: 767px) {
          margin: 20px 0 30px 0;
        }
      }
    }
  }

  .clear-button {
    position: absolute;
    width: 30px;
    height: 30px;
    background-image: url("../../../styles/assets/images/sv/cross_white.svg");
    background-repeat: no-repeat;
    background-color: #C7C7C7;
    top: 10px;
    right: 15px;
    border: none;
    background-size: 15px;
    background-position: center;
    border-radius: 50%;
    padding: 0;
  }

  .none {
    display: none;
  }

  .block {
    display: block;
  }

  .opacity-0 {
    opacity: 0;
  }

  .react-select-menu-list {
    border: 1px solid rgba(68, 68, 68, 1);
    border-radius: 5px;
    font-size: 14px;
  }

  .react-select-menu {
    margin-top: 0;
    z-index: 999;
  }

  .react-select-option {
    padding: 0 12px;
    color: rgba(68, 68, 68, 1);
    cursor: pointer;

    &:active {
      background-color: #ccc;
    }
  }

  .react-select-control {
    box-shadow: none;
  }

  .fs-12 {
    font-size: 12px;
  }
  
  .payment-block-v2 {
    margin-bottom: 0px !important;
    width: 100%;
    gap: 20px;

    @media screen and (min-width: 200px) and (max-width: 599px) {
      gap: 0;
    }
    @media screen and (min-width: 200px) and (max-width: 359px) {
      padding: 0;
    }

    button {
      text-transform: unset !important;
      font-weight: unset !important;
    }

    .text-danger {
      line-height: 0.8 !important;
    }

    .bordered-input {
      color: #343a40 !important;
    }

    .payment-extra-block {
      @media screen and (max-width: 430px) {
        margin: 20px 0;
      }
      @media screen and (max-width: 320px) {
        margin: 10px 0;
      }
    }

    .payment-input {
      width: calc(50% - 10px);

      @media screen and (max-width: 1100px) {
        width: 100%;
        max-width: unset;
      }

      @media screen and (max-width: 991px) {
        width: calc(50% - 10px);
      }

      @media screen and (max-width: 500px) {
        width: 100%;
        max-width: unset;
      }
      @media screen and (max-width: 430px) {
        margin-bottom: 0;
      }

      .btn-primary {
        min-width: unset !important;
        border-radius: 10px;
      }

      .apply-button {
          margin: 0px 20px 0px 0px;
          padding: 10px 24px 15px;
          width: 154px;
      }

      .cancel-button {
        background-color: #0000000F !important;
        color: #000;
      }
    }

    .cert-remainder {
      font-size: 12px !important;
    }

    .mini-promo {
      font-size: 12px !important;
    }

    .cert-success-apply {
      .promo-input-block {
        display: none !important;
      }
    }

    .text-danger {
      line-height: 1 !important;
    }

    .promo-success-apply {
      .promo-input-block {
        display: none !important;
      }
    }

    .payment-input-value {
      font-size: 28px;
    }

    .payment-pay-extra-block {
      display: flex;
      align-items: center;

      label{
        display: inline-flex;
        align-items: center;
        border: 1px solid #000;
        border-radius: 10px;
        padding: 10px 0 10px 15px;
        width: initial;
        margin-bottom: 0;
        &::before{
          position: static;
          transform: none;
          margin-right: 12px;
          left: 15px;
        }
        &:after{
          left: 15px;
        }
      }

      .checked:before {
        background: #000;
      }

      .payment-pay-extra-checkbox {
        border: none;
      }
    }

    .payment-divider {
      border: 1px solid rgba(229, 229, 229, 1);
      width: 100%;
      margin: 20px 0;
      @media screen and (max-width: 767px) {
        margin: 20px 0 30px 0;
      }
    }

    .hide-clear-button {
      .clear-button {
        display: none;
      }
    }

    .title-label {
      color: black;
      font-weight: 500;
      padding: 8px 0 7px;
      margin-bottom: 0;
    }

    .bordered-input {
      border: unset !important;
      border-radius: unset !important;
      padding: unset !important;
      margin-left: unset !important;

      .placeholder {
        color: #0000004D;
      }

      &::placeholder {
        color: #0000004D;
      }
    }
  }

  .clear-button {
    position: absolute;
    width: 30px;
    height: 30px;
    background-image: url("../../../styles/assets/images/sv/cross_black.svg");
    background-repeat: no-repeat;
    background-color: #0000000F;
    top: 50%;
    right: 15px;
    border: none;
    background-size: 10px;
    background-position: center;
    border-radius: 50%;
    padding: 0;
    transform: translate(0%, -50%);
  }
}