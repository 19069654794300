.divider {
  height: 1px;
  background-color: rgba(229, 229, 229, 1);
  width: 100%;
  margin: 20px 0;
  @media screen and (max-width: 320px) {
    margin: 10px 0;
  }
}

.no-margin {
  position: absolute;
  left: 0;
  right: 0;
}