.header {
  padding: 40px 60px 30px 60px;
  margin-bottom: 17px;
  @media screen and (max-width: 1200px) and (min-width: 600px) {
    max-width: 480px;
    margin: 0 auto;
    padding: 40px 0 30px;
    .row {
      flex-wrap: nowrap;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 10px 0 15px;
    align-items: center;
  }
  @media screen and (min-width: 200px) and (max-width: 599px) {
    padding: 15px 20px 0px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 360px) {
    padding: 0px 10px;
    margin-top: 25px !important;
  }
  @media screen and (max-width: 320px) {
    margin-top: 15px !important;
    margin-bottom: 20px;
  }
  .row {
    @media screen and (min-width: 200px) and (max-width: 599px) {
      flex-wrap: wrap;
      gap: 7px;
    }
  }
  &__logo-content {
    @media screen and (min-width: 200px) and (max-width: 599px) {
      width: 50% !important;
    }
  }
  &__logo-wrapper {
    margin-top: -2px;
    margin-right: -25px;
  }

  &__logo {
    display: block;
    width: auto;
  }
  &__step {
    font-style: normal;
    font-weight: 500;
    font-size: 21.425px;
    letter-spacing: 0px;
    line-height: 25px;
    position: relative;
    text-transform: uppercase;
    padding: 2px 10px 4px 10px;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 31px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100px;
      border: 2px solid black;
    }
  }
  &__link {
    text-decoration: none;
    color: #000;
    &:hover {
      text-decoration: none;
      color: #000;
    }
  }
}