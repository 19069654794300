@import "theme/common/light/variables";
@import './theme/common/common';
@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #F9F9F9;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'dlig' on, 'salt' on, 'ss01' on;
}

.card-col {
  background: #1D1D1D;
}

.container {
  max-width: 1280px;
}

.form-col {
  background: #ffffff;
  padding-bottom: 30px;
}

* {
  box-sizing: border-box;
  font-family: 'Basis Grotesque Pro', sans-serif;
}

body > iframe {
  display: none;
}