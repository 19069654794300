.personal_account {
  &__wrapper {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    padding: 20px 0 40px;
    cursor: pointer;
    display: block;
    @media screen and (max-width: 470px) {
      padding: 20px;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__logo {
    &_icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  &__title {
    font-weight: 400;
    font-size: 16.12px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 0;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 12.09px;
    line-height: 15px;
    color: #fff;
    margin-bottom: 0;
  }
  &__logo {
    &_content {
      padding: 10px 0 10px 10px;
      &:hover {
        background: rgba(255, 255, 255, 0.09);
        border-radius: 16px;
      }
    }
    &_active {
      background: rgba(255, 255, 255, 0.09);
      border-radius: 16px;
    }
  }
}
.wrapper{
  &__content {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
  &__title {
    padding: 4px 0 6px;
  }
  &__subtitle {
    margin: -3px 0 7px;
  }
}