.navigation_menu {
  @media screen and (max-width: 992px) {
    &__hidden {
      transform: translateX(-100%)!important;
    }
  }

  &__main {
    padding: 40px 0;
    margin-left: auto;
    margin-right: auto;
    flex: 1 1 auto;
    width: 100%;
    @media screen and (max-width: 470px) {
      padding: 20px;
      margin-top: 20px;
    }
    @media screen and (max-height: 820px) {
      padding: 30px 0;
    }
  }
  &__wrapper {
    max-width: 600px;
    width: 50%;
    @media screen and (max-width: 992px) {
      width: 100%;
      max-width: 100%;
      transition: transform 0.3s ease-in-out;
      transform: translateX(0);
    }
    @media screen and (max-width: 470px) {
      max-width: 470px;
    }
  }
  &__body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 101;
    background: #fff;
  }
  &__footer {
    @media screen and (max-width: 470px) {
      display: none;
    }
  }
}
.decorative {
  &__line {
    height: 2px;
    background-color: rgba(0, 0, 0, 0.09);
    width: 100%;
    margin: 20px 0;
    @media screen and (max-width: 470px) {
      display: none;
    }
  }
}
.main_panel {
  &__show {
    display: block !important;
    z-index: 100;
  }
  &__wrapper {
    padding-top: 40px;
    @media screen and (min-width: 1200px) {
      flex: 1 1 auto;
      max-width: 100%;
      margin-left: 600px;
    }
    @media screen and (max-width: 992px) {
      max-width: 100%;
      flex: 1 1 auto;
      margin-left: 0;
    }
  }
}
.personal_account {
  &__container {
    @media screen and (min-width: 1200px) {
      max-width: 100%;
    }
  }
}