.form-price {
  background: none;
  padding: 0;
}

.btn-primary {
  max-width: unset !important;
}

.chat_open_button_mobile {
  display: none;
  @media screen and (max-width: 996px) {
    display: block;
    position: absolute;
    right: 0px;
    top: -100px;
    z-index: 100000;
    cursor: pointer;
  }
}