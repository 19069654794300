.footer {
  &__wrapper {
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 0 40px;
    &_title {
      padding: 8px 0 7px;
    }
    &_subtitle {
      padding: 4px 0 6px;
    }
  }
  &__title {
    font-size: 12.09px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.363px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.91);
    margin: 0;
  }
  &__subtitle {
    font-size: 16.12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.242px;
    color: rgba(0, 0, 0, 0.91);
    margin: 0;
    &_link {
      color: inherit;
      text-decoration-line: underline;
      &:hover {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}