.full-book-label {
  margin-left: 32px;
  @media screen and (max-width: 767px) {
    margin-left: 1.5rem;
  }
  @media screen and (max-width: 575px) {
    margin-left: 1rem;
  }
}

.booking-limit-warning {
  margin: -8px 32px 8px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: bisque;
  padding: 10px;

  @media screen and (max-width: 767px) {
    margin: 0px 1.5rem 32px;
  }
  @media screen and (max-width: 575px) {
    margin: 0px 1rem 32px;
  }
}