.price__wrapper {
  padding: 60px 0 20px;
  @media screen and (max-width: 320px) {
    padding: 30px 0 10px;
  }
}

.subscription {
  margin: 0px 60px 15px 60px;
  @media screen and (max-width: 1199px) and (min-width: 600px) {
    max-width: 480px;
    margin: 0 auto;
  }
  @media screen and (min-width: 200px) and (max-width: 599px) {
    margin: 0px 20px 15px;
  }
  @media screen and (max-width: 320px) {
    margin: 0px 10px 15px;
  }
  &-title {
    font-size: 20.15px;
    line-height: 25px;
    letter-spacing: 0.1px;
    margin-bottom: 0!important;
    padding-bottom: 20px;

    @media screen and (max-width: 320px) {
      font-size: 16.12px;
      padding-bottom: 10px;
    }
  }
  &-text {
    margin-bottom: 0;
    font-size: 16px;
    span {
      font-weight: bold;
    }
  }
  .mb-4 {
    @media screen and (max-width: 320px) {
      margin-bottom: 15px !important;
    }
  }
  .promo-input-block {
    input {
      width: 80% !important;
    }
  }

  .checkout__input-wrapper {
    margin: 0;
    padding: 60px 0 20px;

    &:before {
      display: none;
    }
    @media screen and (min-width: 321px) and (max-width: 599px) {
      padding: 40px 0 0;
    }
    @media screen and (max-width: 320px) {
      padding: 30px 0 10px;
    }
  }

  .public-offer-consent {
  }

  .range-block-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 20px;
    margin-right: -15px;
    margin-left: -15px;
    height: 155px;
  }

  .range-block {
    width: calc(100% - 30px);
    margin: 0 15px;
    position: relative;
    @media screen and (max-width: 991px) {
      width: calc(100% - 30px);
      min-width: auto;
    }
  }

    input[type=range]::after {
      content: '';
      border-radius: 5px;
      margin-top: 13px;
      height: 4px;
      position: absolute;
      top: 0;
      left: 0;
      padding: 1px 0 1px 0;
      background: #0056B3; 
      width: var(--range-fill, 0%); 
      z-index: 1;
    }

  input[type=range] {
    margin: 11.5px 0 15px;
    height: 30px;
    -webkit-appearance: none;
    padding: 1px 0 1px 0;
    width: 100%;
    position: relative;
    background: transparent;
    @media screen and (max-width: 991px) {
      max-width: none;
    }
  }
  .range-minimum {
    position: absolute;
    font-size: 20.15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; 

    font-family: "Basis Grotesque Pro", sans-serif;
    letter-spacing: 0.1px;
    @media screen and (max-width: 991px) {
      bottom: 0px;
    }
  }
  .range-maximum {
    position: absolute;
    right: 0px;
    font-size: 20.15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;

    font-family: "Basis Grotesque Pro", sans-serif;
    font-family: "Basis Grotesque Pro";
    letter-spacing: 0.1px;
    @media screen and (max-width: 991px) {
      bottom: 0px;
      right: 0px;
    }
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 5px;
    background-color: #00000017;
    z-index: 10;
  }
  input[type=range]:active::-webkit-slider-thumb {
    background: #F0F0F0;
  }

  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 6px 13px 0px #0000001F, 0px 0.5px 4px 0px #0000001F;
    height: 27px;
    width: 27px;
    border-radius: 100px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -11px;
    z-index: 10;
  }
  .slider-thumb-tooltip-wrapper {
    visibility: hidden;
    margin: 0px calc(27px / 2);
    position: relative;
    width: calc(100% - 27px);

    font-family: "Basis Grotesque Pro", sans-serif;
    font-family: "Basis Grotesque Pro";
    font-size: 20.15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.1px;
  }
  .slider-thumb-tooltip {
    position: absolute;
    bottom: 38px;
    margin-left: calc(var(--range-fill-current, 0%));
    transform: translateX(-50%);
    padding: 10px 15px;
    background-color: #0056B3;
    color: #FFFFFF;
    border-radius: 10px;
    white-space: nowrap;
    visibility: hidden;
    z-index: 100;
  }
  input[type=range]:active + .slider-thumb-tooltip-wrapper > .slider-thumb-tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: #0056B3 transparent transparent transparent;
  }
  input[type=range]:active + .slider-thumb-tooltip-wrapper > .slider-thumb-tooltip::after {
    visibility: visible;
    opacity: 1;
  }
  input[type=range]:active + .slider-thumb-tooltip-wrapper > .slider-thumb-tooltip {
    visibility: visible;
    opacity: 1;
  }

  .slider-thumb-tooltip-wrapper[tooltip-mode='1'] {
    margin-left: 33px;
  }
  .slider-thumb-tooltip-wrapper[tooltip-mode='3'] .slider-thumb-tooltip{
    left: -31px;
  }
  .slider-thumb-tooltip-wrapper[tooltip-mode='1'] .slider-thumb-tooltip::after {
    left: calc(50% - 24px) !important;
  }
  .slider-thumb-tooltip-wrapper[tooltip-mode='2'] .slider-thumb-tooltip::after {
    left: calc(50% - 5px) !important;
  }
  .slider-thumb-tooltip-wrapper[tooltip-mode='3'] .slider-thumb-tooltip::after {
    left: calc(50% + 26px) !important;
  }
  
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: #C4C4C4;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: #C4C4C4;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #FFFFFF;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #FFFFFF;
  }

  .price-block {
    display: none;
    .price-info{
      background: none;
      padding: 0;
    }
    @media screen and (max-width: 991px) {
      display: block;
    }
  }
  .radio-input {
    img {
      margin-left: 4px;
    }
  }
}
.certificateError{
  font-size: 16px;
}
.subscription_popup_text {
  .subscription_popup_text_item{
    margin-top: 10px;
  }
  .subscription_popup_agree{
    display: block;
    margin-top: 10px;
    color: #737373;
    font-size: 17px;
  }
  .subscription_popup_hr{
    display: block;
    width: 100px;
    height: 1px;
    background: #dcdcdc;
    margin: 5px 0;
  }
}

.certificate {
  &_balance {
    color: #01A830;
    font-size: 18px;
  }
}

.buy-button {
  width: 100%;
  min-width: unset !important;
}
.disabled-button {
  background-color: gray !important;
}

.buy_button_wrapper {
  @media screen and (max-width: 991px) {
    max-width: 185px;
  }
  @media screen and (max-width: 405px) {
    max-width: none !important;
    padding-top: 20px;
  }
  .btn-primary {
    border-radius: 10px !important;
  }
}
 
.checkout__to_payment {
  padding: 60px 0 20px;
  @media screen and (max-width: 991px) {
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: fixed !important;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 15px 20px !important;
    background-color: white;
    box-shadow: 0px -5px 5px 0px #00000005, 
                0px -10px 10px 0px #00000005, 
                0px -20px 20px 0px #00000005;
    z-index: 1000;
  }
  @media screen and (max-width: 320px) {
    padding: 15px 10px !important;
  }
  .buy_button_wrapper {
    @media screen and (max-width: 320px) {
      max-width: none;
    }
  }
}

.is-help-needed {
  font-size: 16px;
  padding-top: 60px;
  @media screen and (max-width: 991px) {
    margin-bottom: 100px;
    width: 70%;
  }
  @media screen and (min-width: 321px) and (max-width: 599px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 430px) {
    margin-bottom: 180px;
  }
  @media screen and (max-width: 320px) {
    padding-top: 30px;
  }

  a {
    margin: 0;
  }
}

.chat_open_button {
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 100000;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    display: none;
  }
}

.icon-wrapper {
  margin-top: -2px;
  width: 18px;
  height: 18px;
}

.subscription_deposite {
  font-size: 28px;
}
.button-disabled {
  background: #0000000F !important;
  color: #0000004D !important;
}
.checkout__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .buy_button_wrapper {
    .btn-primary {
      margin-bottom: 0!important;
    }
  }
  @media screen and (max-width: 1200px) and (min-width: 600px) {
    max-width: 480px;
  }
  @media screen and (max-width: 991px) {
    flex-wrap: nowrap;
  }
  @media screen and (max-width: 405px) {
    flex-wrap: wrap;
  }
}