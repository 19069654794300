.personalData {
  &_wrapper {
    padding: 20px 0;
    @media screen and (max-width: 992px) {
      padding: 0 0 20px 0;
    }
  }
  &__block {
    padding-right: 0;
    &_title {
      margin: 20px 60px;
      @media screen and (max-width: 992px) {
        margin: 20px;
      }
      @media screen and (max-height: 820px) {
        margin: 10px 60px;
      }
    }
    &_subtitle {
      margin: 40px 60px 20px;
      @media screen and (max-width: 992px) {
        margin: 20px;
      }
    }
  }

  &_title {
    color: var(--service-black-100, #000);
    font-size: 28.57px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.143px;
    padding: 4px 0 16px;
  }
  &_subtitle {
    font-weight: 700;
    font-size: 20.15px;
    line-height: 25px;
    letter-spacing: 0.101px;
    padding-bottom: 5px;
  }
  &_title,
  &_subtitle {
    margin: 0;
  }
  &_form {
    &_content {
      margin-right: 0;
    }
  }
}
.decorative {
  &__line {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.09);
    width: 100%;
    margin: 20px 0;
  }
}