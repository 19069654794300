.insurance-modal {
  .modal-header {
    padding: 1.5rem 2.5rem 1rem 2.5rem;
  } 

  .modal-body {
    flex-direction: column;
  }

  .modal-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 28px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 2.5rem 2.5rem;
  }
}