@mixin hoverEffect {
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    transition: 0.3s;
  }
}

.headerMenu {
  &:hover {
    background-color: #0000000F;

    :hover {
      .check {
        img {
          content: url('../../../styles/assets/images/sv/check.svg');
        }
      }
    }
  }
  position: relative;
  border-radius: 50px;
  &_menuItem {
    color: black;
    &:hover {
      text-decoration: none;
    }
  }
  &_authButton {
    background: none;
    outline: none;
    border: none;
    padding: 0;
    @include hoverEffect;
  }
  &_icon {
    @include hoverEffect;
  }
}

.check {
  position: absolute;
  top: 6px;
  right: -15px;
  img {
    width: 100%;
  }
}